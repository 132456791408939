import React from "react";
import Header from "../components/atoms/header";
import ManualContainer from "../components/atoms/ManualContainer/ManualContainer";
import { Helmet } from "react-helmet";
function Plans() {
  return (
    <div>
      <Helmet>
        <title>Plans</title>
        <link rel="canonical" href="https://masterwizr.com/plans" />
      </Helmet>
      <Header />
      <ManualContainer
        image={
          "https://cdn.cf.masterwizr.com/info_page-0001.jpg"
        }
      />
    </div>
  );
}
export default Plans;
